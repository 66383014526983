const routes = [
  {
    path: ["/"],
    exact: true,
    component: "Home",
  },
  {
    path: ["/what-we-do"],
    exact: true,
    component: "WhatWeDo",
  },
  {
    path: ["/how-we-help"],
    exact: true,
    component: "HowWeHelp",
  },
];

export default routes;
