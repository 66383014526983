import { Row, Col, Modal } from "antd";
import { withTranslation, TFunction } from "react-i18next";
import { SvgIcon } from "../../common/SvgIcon";
import Container from "../../common/Container";
import { useState } from "react";

import i18n from "i18next";
import {
  FooterSection,
  Title,
  NavLink,
  Extra,
  LogoContainer,
  Para,
  Large,
  Chat,
  Empty,
  FooterContainer,
  Language,
  Label,
  LanguageSwitch,
  LanguageSwitchContainer,
} from "./styles";

interface SocialLinkProps {
  href: string;
  src: string;
}

const hasPrivacyParam = window.location.search.includes("privacy");

const Footer = ({ t }: { t: TFunction }) => {
  const [open, setOpen] = useState(hasPrivacyParam);
  const handleChange = (language: string) => {
    i18n.changeLanguage(language);
  };

  const SocialLink = ({ href, src }: SocialLinkProps) => {
    return (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        key={src}
        aria-label={src}
      >
        <SvgIcon src={src} width="25px" height="25px" />
      </a>
    );
  };

  const privacyPolicy = `
  Your Privacy Matters to Us

  At Fitvibe, we are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy outlines how we collect, use, and safeguard your data when you use our app and services.

  1. Information We Collect

  Personal Information: When you create an account, we may collect personal information such as your name, email address, age, gender, and fitness goals.
  Usage Data: We collect data on how you use the app, including the features you interact with, your workout routines, meal logs, and progress tracking.
  Device Information: We gather information about the devices you use to access Fitvibe, including device type, operating system, and unique device identifiers.
  2. How We Use Your Information

  Personalization: To provide a personalized experience, including tailored workouts, meal plans, and AI-driven insights.
  Improvement: To analyze usage trends and improve the functionality and performance of our app.
  Communication: To send you updates, notifications, and promotional content related to Fitvibe. You can opt out of promotional communications at any time.
  3. Data Security

  Encryption: We use advanced encryption methods to protect your data during transmission and storage.
  Access Control: Only authorized personnel have access to your personal information, and they are bound by strict confidentiality agreements.
  4. Sharing Your Information

  Third-Party Services: We may share your information with trusted third-party service providers who assist us in operating the app and providing services to you. These providers are obligated to protect your data and use it only for the purposes specified by Fitvibe.
  Legal Compliance: We may disclose your information if required by law or in response to a valid legal request.
  5. Your Rights

  Access & Control: You have the right to access, update, or delete your personal information at any time through your account settings.
  Data Portability: You can request a copy of your data in a structured, commonly used format.
  Withdraw Consent: You can withdraw your consent for us to process your data at any time, although this may affect your ability to use certain features of the app.
  6. Changes to Our Privacy Policy
  We may update this Privacy Policy from time to time. We will notify you of any significant changes by posting the new policy on our website and app, and we encourage you to review it periodically.

  Contact Us
  If you have any questions or concerns about our Privacy Policy or the handling of your personal information, please contact us at:

  Email: hello@fitvibeapp.com
  `;

  return (
    <div id="footer">
    <Modal open={open} onOk={() => setOpen(false)} onCancel={() => setOpen(false)} closable>
      {privacyPolicy}
    </Modal>
      <FooterSection>
        <Container>
          <Row justify="space-between">
            <Col lg={10} md={10} sm={12} xs={12}>
              <Language>{t("Contact")}</Language>
              {/* <Large to="/">{t("Tell us everything")}</Large> */}
              <Para>
                {t(`Do you have any question? Feel free to reach out.`)}
              </Para>
              <a href="mailto:hello@fitvibeapp.com">
                <Chat>{t(`Let's Chat`)}</Chat>
              </a>
            </Col>
            <Col lg={8} md={8} sm={12} xs={12}>
              <Title>{t("Policy")}</Title>
              <Large to="/" onClick={() => setOpen(true)} >{t("Privacy policy")}</Large>
              <Large to="/what-we-do">What we do</Large>
              <Large to="/how-we-help" >How Fitbite helps you</Large>
            </Col>
            {/* <Col lg={6} md={6} sm={12} xs={12}>
              <Empty />
              <Large to="/">{t("Support Center")}</Large>
              <Large to="/">{t("Customer Support")}</Large>
            </Col> */}
          </Row>
          <Row justify="space-between">
            {/* <Col lg={8} md={8} sm={12} xs={12}>
              <Title>{t("Company")}</Title>
              <Large to="/">{t("About")}</Large>
              <Large to="/">{t("Blog")}</Large>
              <Large to="/">{t("Press")}</Large>
              <Large to="/">{t("Careers & Culture")}</Large>
            </Col> */}
            <Col lg={6} md={6} sm={12} xs={12}>
              <Label htmlFor="select-lang">{t("Language")}</Label>
              <LanguageSwitchContainer>
                <LanguageSwitch onClick={() => handleChange("en")}>
                  <SvgIcon
                    src="united-states.svg"
                    aria-label="homepage"
                    width="30px"
                    height="30px"
                  />
                </LanguageSwitch>
                <LanguageSwitch onClick={() => handleChange("es")}>
                  <SvgIcon
                    src="spain.svg"
                    aria-label="homepage"
                    width="30px"
                    height="30px"
                  />
                </LanguageSwitch>
              </LanguageSwitchContainer>
            </Col>
          </Row>
        </Container>
      </FooterSection>
      <Extra>
        <Container border={true}>
          <Row
            justify="space-between"
            align="middle"
            style={{ paddingTop: "3rem" }}
          >
            <NavLink to="/">
              <LogoContainer>
                <SvgIcon
                  src="logo.svg"
                  aria-label="homepage"
                  width="101px"
                  height="64px"
                />
              </LogoContainer>
            </NavLink>
            <FooterContainer>
              <SocialLink
                href="https://github.com/chiItepin/"
                src="github.svg"
              />
              <SocialLink
                href="https://x.com/ChiItepin"
                src="twitter.svg"
              />
              <SocialLink
                href="https://www.linkedin.com/in/hector-almeida-581700108/"
                src="linkedin.svg"
              />
            </FooterContainer>
          </Row>
        </Container>
      </Extra>
    </div>
  );
};

export default withTranslation()(Footer);
